@import url("./font.css");

:root {
  --color-primary: #ed1c24;
  --color-primary-alt: #fa6e75;
  --color-secondary: #0f43a9;

  --color-on-primary: #ffffff;
  --color-on-secondary: #ffffff;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  font-family: "Minecraft", monospace;
  color: black;
  font-size: 18px;
  background-image: url("./images/background-mobile.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}

input {
  width: 60px;
  padding: 1rem 0.5rem 0.75rem 0.5rem;
  font-family: "Minecraft", monospace;
  font-size: 22px;
  border: 2px solid var(--color-secondary);
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
}

.col-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mint-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mint-box {
  background-image: url("./images/mint-box.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 375px 195px;
  width: 375px;
  height: 195px;
  padding: 19px 38px 33px 52px;
}

.mint-box__inner {
  padding: 0 1rem;
}

.mint-box__title {
  margin: 0 0 0.25rem 0;
  font-weight: normal;
  text-transform: uppercase;
}

.mint-box__value {
  font-size: 28px;
}

.mint-box__button-container {
  display: flex;
  flex-direction: row;
}

.mint-box__button-container > div:first-child {
  flex: 1;
}

.button {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  border: none;
  font-family: "Minecraft", monospace;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  padding: 1rem 2rem 0.5rem 2rem;
  cursor: pointer;
}

.button:hover {
  background-color: var(--color-primary-alt);
}

.mint-box__button {
  width: 100%;
}

.mint-box__data {
  display: flex;
  flex-direction: row;
  padding: 1rem 0 0.5rem 0;
}

.mint-box__data > div {
  flex: 1;
}

.mint-box__data > div + div {
  padding-left: 1rem;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.back-arrow {
  background-image: url("./images/arrow-back.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100px 66px;
  display: block;
  width: 100px;
  height: 66px;
}

.trump-pepe {
  max-width: 100%;
}

.live-indicator {
  padding: 1rem 0;
  background-color: var(--color-primary-alt);
  color: var(--color-on-primary);
  text-align: center;
  font-size: 16px;
}

.wallet-adapter-button-trigger:not([disabled]) {
  color: var(--color-on-secondary);
  background-color: var(--color-secondary);
  border: 4px solid var(--color-secondary);
  font-family: "Minecraft", monospace;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 22px;
}
.wallet-adapter-button-trigger:not([disabled]):hover {
  background-color: var(--color-on-secondary) !important;
  color: var(--color-secondary);
}

.error {
  display: block;
  color: var(--color-primary);
  padding: 1rem;
  margin: 1rem;
  background: white;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) {
  body {
    background-image: url("./images/background.png");
  }

  .mint-container {
    flex-direction: row;
    justify-content: center;
  }

  .mint-container > div {
    flex: 1;
  }

  .mint-container > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .mint-container > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .mint-box {
    background-size: 421px 219px;
    width: 421px;
    height: 219px;
    padding: 22px 43px 38px 59px;
  }

  .mint-box__data {
    padding-bottom: 0.25rem;
  }

  .mint-box__value {
    font-size: 32px;
  }

  .button {
    font-size: 44px;
    line-height: 44px;
    padding-top: 1.25rem;
  }

  .live-indicator {
    padding: 1rem 0.5rem;
    font-size: 20px;
  }

  input {
    padding: 1.38rem 0.75rem;
  }

  .error {
    margin: 0;
  }
}
